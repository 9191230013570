//Turbo.session.drive = false
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'debounce'


import "jquery/dist/jquery.js";
import '@hotwired/turbo-rails'
import '@hotwired/stimulus'
import "flowbite/dist/flowbite.turbo.js";


import Rails from 'rails-ujs';
import * as ActiveStorage from 'activestorage';
import LocalTime from "local-time"
import '@fortawesome/fontawesome-free/js/all';

Rails.start();

ActiveStorage.start();
LocalTime.start()

if (!window.useTailwind) {
    import('materialize-css/dist/js/materialize.min.js').then(() => {
      import("./application/javascripts/custom.js").catch(err => {
        console.error('Error loading Custom JS:', err);
      });

      import("./application/javascripts/pages.js").catch(err => {
        console.error('Error loading Pages JS:', err);
      });

      import("./application/javascripts/sales.js").catch(err => {
        console.error('Error loading Sales JS:', err);
      });
    }).catch(err => {
      console.error('Error loading Materialize:', err);
    });
  }
import 'controllers'
import "./channels"
